<template>
  <div>
    <!-- Static sidebar for desktop -->
    <div>
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div class="fixed inset-0 z-50 flex" role="dialog" aria-modal="true" v-show="mainMenuToggle">
        <!--
          Off-canvas menu overlay, show/hide based on off-canvas menu state.

          Entering: "transition-opacity ease-linear duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "transition-opacity ease-linear duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300" aria-hidden="true"
           :class="[mainMenuToggle ? 'opacity-100' : 'opacity-0']"
        >
        ></div>

        <!--
          Off-canvas menu, show/hide based on off-canvas menu state.

          Entering: "transition ease-in-out duration-300 transform"
            From: "-translate-x-full"
            To: "translate-x-0"
          Leaving: "transition ease-in-out duration-300 transform"
            From: "translate-x-0"
            To: "-translate-x-full"
        -->
        <div class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col transition ease-in-out duration-300 transform"
           :class="{'-translate-x-full': !mainMenuToggle, 'translate-x-0': mainMenuToggle}">
          <!--
            Close button, show/hide based on off-canvas menu state.

            Entering: "ease-in-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in-out duration-300"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div class="absolute top-0 right-0 -mr-12 pt-2">
            <button @click="mainMenuToggle = false" type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
              <a
                @click="navChange('Tracker')"
                class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                :class="[currentPage === 'Tracker' ? 'bg-gray-100' : '']"
              >
                <!--
                  Heroicon name: outline/home

                  Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500"
                -->
                <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-500 mr-4 flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
                Board
              </a>

              <a
                @click="navChange('Projects')"
                class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                :class="[currentPage === 'Projects' ? 'bg-gray-100' : '']"
              >
                <!-- Heroicon name: outline/folder -->
                <svg class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                </svg>
                Projects
              </a>

              <a
                @click="navChange('Reports')"
                class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                :class="[currentPage === 'Reports' ? 'bg-gray-100' : '']"
              >
                <!-- Heroicon name: outline/chart-bar -->
                <svg class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                Reports
              </a>
            </nav>
          </div>
        </div>

        <div class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>

    </div>

    <div class="fixed w-full top-0 bg-white bg-opacity-50 flex z-40 justify-items-end" id="app-nav">
      <div class="flex-none flex">
          <nav class="px-2 flex" v-if="currentUser">

            <button
              type="button"
              class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              @click="mainMenuToggle = true"
            >
              <span class="sr-only">Open sidebar</span>
              <!-- Heroicon name: outline/menu-alt-2 -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </button>

            <button
              v-show="onTracker"
              type="button"
              id="tracker-build"
              class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              @click="navChange('Tracker Build')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
              </svg>
              <span>Build</span>
            </button>
          </nav>
      </div>
      <div class="flex-grow flex">
        <div class="flex-grow"></div>
        <div v-if="userTrialing">
          <router-link to="/subscription" id="trial-link" type="button" class="hidden md:inline-flex items-center px-1 py-1 mr-2 mt-1 border border-transparent text-sm font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            {{currentUser.trial_end}}.
          </router-link>
        </div>
        <div v-if="userTrialExpired">
          <router-link to="/subscription" id="trial-link" type="button" class="hidden md:inline-flex items-center px-1 py-1 mr-2 mt-1 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Trial expired.&nbsp;<span class="underline">Subscribe now</span>
          </router-link>
        </div>

      <a class="mr-1 items-center hidden md:inline-flex px-1 border border-transparent text-sm font-medium rounded-md text-red-500 bg-transparent hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
         href="https://www.youtube.com/watch?v=fuV8SMyMsIg"
         target="_blank"
      >
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path></svg>
        Watch video
      </a>
      <button v-if="currentUser" @click="IMTopicActivate(90529)" id="appFeedback" type="button" class="mr-2 hidden md:inline-flex items-center px-1 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-transparent hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <svg class="-ml-0.5 mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clip-rule="evenodd"></path></svg>
        Feedback?
      </button>
      <button v-if="currentUser" @click="IMWidgetActivate" id="appHelp" type="button" class="inline-flex mr-2 items-center px-1 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-transparent hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <svg class="-ml-0.5 mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
        Help
      </button>

      <div class="flex-shrink-0">
          <!-- Profile dropdown -->
          <div class="mr-3 mt-2 relative" v-if="currentUser">
            <div>
              <div
                engagekit-badges-unseen-activities-badge
                engagekit-badges-launcher
                class="cursor-pointer rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                <span class="sr-only">Badges</span>
                <img class="h-8" src="@/assets/badge.png"/>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0">
          <!-- Profile dropdown -->
          <div class="mr-3 mt-2 relative" v-if="currentUser">
            <div>
              <button
                @click="profileToggle = !profileToggle"
                class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" id="user-menu" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img class="h-7 w-7 rounded-full" src="@/assets/avatar.png"/>
              </button>
            </div>
            <div v-if="profileToggle" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
              <router-link
                      @click="profileToggle = false"
                      to="/profile"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
              >Profile</router-link>
              <router-link
                      @click="profileToggle = false"
                      to="/profile-edit"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
              >Settings</router-link>
              <router-link
                      @click="profileToggle = false"
                      to="/subscription"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
              >Subscription</router-link>
              <a @click="logout" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Log out</a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <main class="w-full" style="margin-top: 36px;">
      <router-view />
    </main>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import Plausible from 'plausible-tracker';
  import {v4 as uuidv4} from "uuid";
  import dayjs from "dayjs";

  const { enableAutoPageviews } = Plausible({
    domain: 'getkairo.com',
    hashMode: true
  });
  enableAutoPageviews();

  export default {
    data() {
      return {
        profileToggle: false,
        mainMenuToggle: false,
        bodyClass: '',
        freeAvailable: true,
        showSidebar: false,
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      userTrialing() {
        if (this.currentUser && this.currentUser.status === 'trialing') return true;
        return false;
      },
      userTrialExpired() {
        if (this.currentUser && this.currentUser.status === 'trial_expired') return true;
        return false;
      },
      onTracker() {
        return this.$route.matched.some(({ name }) => name === 'Tracker');
      },
      currentPage() {
        return this.$route.name;
      }
    },
    methods: {
      ...mapActions("auth", [
        "attemptLogout",
      ]),
      ...mapActions(["resetEverything", "projectsResetAll"]),
      logout() {
        this.profileToggle = false;
        this.attemptLogout()
            .then(resp => {
              this.resetEverything();
              this.projectsResetAll();

              if (typeof window.InlineManual !== 'undefined') {
                window.InlineManual("shutdown");
                window.InlineManual("boot", {
                  uid: 'anon-' + uuidv4()
                });
              }

              if (typeof window.EngageKit !== 'undefined') {
                window.EngageKit.reset();
              }

              this.$router.push({name: "Login"});
              console.log("logged out", resp);
            })
            .catch(error => {
              location.reload();
              console.error("Problem with logout", error);
            });
      },
      navChange(path) {
        this.showSidebar = false;
        this.mainMenuToggle = false;
        this.$router.push({name: path});
      },
      updateBG(className) {
        this.bodyClass = className;
      },
      IMTopicActivate(topicId) {
        if (window.inline_manual_player.getCurrentTopic() === "90529") {
          window.inline_manual_player.deactivate();
        } else {
          window.inline_manual_player.activateTopic(topicId);
        }
      },
      IMWidgetActivate() {
        window.inline_manual_player.togglePanel();
      }
    },
    mounted() {
      if (typeof window.InlineManual !== 'undefined') {
        if (this.currentUser) {
          window.InlineManual("boot", {
            uid: this.currentUser.id,
            plan: this.currentUser.app_plan,
            name: this.currentUser.user_metadata.full_name,
            created: dayjs(this.currentUser.created_at).unix()
          });
        }
      }

      // EngageKit
      window.EngageKit.ready(function () {
        window.EngageKit.setTheme("green");
      });

      let trialEndAt = null;

      if (this.currentUser) {
        if (this.currentUser.app_metadata.trial_end) {
          trialEndAt = dayjs.unix(this.currentUser.app_metadata.trial_end).toISOString();
        }

        const userContext = {
          userId: this.currentUser.id,
          userIdentityVerificationHash: this.currentUser.app_metadata.ek_token,
          userProperties: {
            createdAt: this.currentUser.created_at,
            trialEndAt: trialEndAt,
            subscriptionPlan: this.currentUser.app_plan,
            subscriptionStatus: this.currentUser.status,
            subscriptionPlanDuration: this.currentUser.app_plan_duration,
            email: this.currentUser.email
          },
          reservedUserProperties: {
            name: this.currentUser.user_metadata.full_name
          }
        }

        window.EngageKit.init(
          "client_L1KhvwrU03aUnh3C8yYtEUCEmhjZQa2K4tvRJoxW3C4Tlfk1",
          userContext,
          {
            rewardClaimedCallback(reward) {
              const trial = document.getElementById('trial-link');
              const days = parseInt(trial.text.replace(/[^0-9]/g, ""));
              const newDays = days + reward.reward.amount;
              let daysText = 'days';
              if (newDays < 2) daysText = 'day';
              trial.text = `Trial expires in ${newDays} ${daysText}`;
            }
          }
        );
      }

    },
  };
</script>
<style>
  .bg-grad-1 {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  .bg-grad-2 {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  .bg-grad-3 {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  }
  .bg-grad-4 {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .bg-grad-5 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  }
  .bg-grad-6 {
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  }
  .bg-grad-7 {
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  }
  .bg-grad-8 {
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  }
</style>
